// @flow
import Parser from 'html-react-parser';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import * as React from 'react';
import _get from 'lodash/get';
import _isNull from 'lodash/isNull';
import Contents from './Contents/index';
import HelmetWrapper from '../HelmetWrapper';
import forEach from '../../polyfills/forEach';

import { getDrugInfos, retrieveDocument } from './../../utils/api';
import { slugify, safeDocSlugReplace, getSafeHtml, unescapeHTML } from '../../utils/common';

import './styles.less';
import { withTranslation } from 'react-i18next';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import MenuContainer from '../../containers/MenuContainer';
import { generateSVG, isIE, getImageSize, getPathnameWithoutModal, isDocumentsRoute, isModalWindowRoute } from '../../utils/common';
import config from '../../config.json';

import i18next from 'i18next';

type Props = {
  prevLocation: string;
  updateParentUrl: Function,
  history: any;
  t: Function;
}

type State = {
  slug: string,
  title: string,
  content: string,
  opened: boolean,
  openedConfirmDeletion: boolean,
  isDocumentLoaded: boolean,
};

// TODO: remove after test
const demoDocument = {
  title: 'Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis?',
  content: '<h1>Heading 1 - Lorem ipsum dolor sit amet, consectetur adipisicing elit. At, cupiditate dignissimos dolorum eligendi enim excepturi harum iure magnam mollitia voluptas. Adipisci aperiam distinctio doloremque neque nihil omnis possimus, vel voluptatum!</h1>\n' +
    '<h2>Heading 2 - Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dignissimos ipsa, maxime! Aut, consequatur, debitis? Accusantium animi consectetur dicta dolore et ex, facere hic laboriosam libero molestias mollitia nesciunt praesentium suscipit.</h2>\n' +
    '<h3>Heading 3 - Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus consectetur culpa, delectus distinctio dolore eius expedita facere id ipsa ipsum itaque labore maiores officia quaerat quam quo similique ullam vero.</h3>\n' +
    '<p><strong>Paragraph</strong> - Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus dolorem molestiae necessitatibus officiis provident qui veniam voluptate voluptatibus? Aspernatur culpa error est harum possimus quaerat tenetur voluptate. Illo, incidunt, voluptate.</p>\n' +
    '<strong>Strong - Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias aperiam beatae consequatur, culpa dolores eaque eos esse, et facere libero nulla praesentium, quis ratione reprehenderit sint sit tenetur ullam velit?</strong>\n' +
    '<em>Em - Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit incidunt odio vitae voluptas! Alias, aliquid animi beatae dolorem, eum facere fugit iste quasi rem reprehenderit unde veniam! Expedita, officiis, sequi?</em>\n' +
    '<blockquote>\n' +
    '    quote - Lorem ipsum dolor sit amet, consectetur adipisicing elit. A, adipisci architecto consequuntur cum dolore dolorum error, est expedita nisi officia praesentium quam, sequi similique sint sit tempora veritatis? Ipsa, quod?\n' +
    '</blockquote>\n' +
    '<p class="caption">Caption - Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus alias aliquid, culpa, cumque dolorem, dolorum id inventore maxime minima mollitia nemo nesciunt numquam qui quos reiciendis repellat sapiente similique sunt.</p>\n' +
    '<a><i class="material-icons">link</i>Link</a>',
};

const SelectArea = (props) => (
  <h1 className={`select-area${props.isSelectExpanded ? ' select-area--expanded' : ''}`}>
    {!props.isSelectLoaded && <LoadingSpinner />}
    {props.selectedProduct && (
      <React.Fragment>
        <span className="select-area__compound-component">{props.productCompoundComponent}</span>
        <button className="select-area__select-drug" onClick={props.onSelectDrug}>
          <span dangerouslySetInnerHTML={{ __html: getSafeHtml(props.selectedProduct) }}></span>
          <i className="material-icons">keyboard_arrow_down</i>
        </button>
        {/* <button className="select-area__expand-area" onClick={props.onExpandArea}>
          <i className="material-icons">add_circle_outline</i>
        </button> */}
      </React.Fragment>
    )}
  </h1>
);

class Document extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      slug: this.getDocumentPath(),
      content: '',
      navLinksArray: [],
      contentMenu: null,
      scrollTop: 0,
      title: '',
      opened: false,
      openedConfirmDeletion: false,
      isDocumentLoaded: false,
      isSelectLoaded: false,
      isSelectExpanded: false,
    };
    this.smallHeaderRef = React.createRef();
    this.headerRef = React.createRef();
    this.contentsRef = React.createRef();
    this.containerRef = React.createRef();
    this.textRef = React.createRef();
    this.arrowBackRef = React.createRef();
    this.headerWrapperRef = React.createRef();
    //
    this.topOffset = 0;
    this.mmiBannerHeight = 0;
    this.correctTopOffsetAttempts = 5;
    this.clientWidthThreshold = 10;
    this.prevLocation = '/';
  }

  componentDidMount(): void {
    if (this.props.prevLocation) {
      this.prevLocation = this.props.prevLocation;
    }

    if (window.parentIFrame) {
      const id = this.props.isIOS12 ? 'fullscreen-ios-12' : 'fullscreen';
      window.parentIFrame.sendMessage({ id });
    }

    const isFachInfoLink = _get(this.props.location, 'state.detail.isFachInfoLink');
    if (isFachInfoLink) {
      window.scrollTo(0, 0);
    }

    const [grecaptchaBadge] = document.getElementsByClassName('grecaptcha-badge');
    if (grecaptchaBadge) {
      grecaptchaBadge.classList.add('hide-grecaptcha');
    }

    let hash;
    if (window.location.hash) {
      hash = window.location.hash.substr(1);
    } else if (this.props.parentLocation) {
      hash = this.props.parentLocation.hash && this.props.parentLocation.hash.replace('#', '');
    }

    if (this.state.slug === 'document') {
      this.setState(demoDocument, this.onScroll);
      this.setState({ isDocumentLoaded: true }, () => {
        this.props.onDocumentLoaded();
        this.attachScrollHandler();
      });
    } else {
      this.handleRetrieveDocument(this.state.slug, hash);
    }

    if (!this.checkClassName(this.containerRef.current.classList, 'fade-enter') && !this.checkClassName(this.containerRef.current.classList, 'fade-enter-active') && this.contentsRef.current) {
      this.contentsRef.current.className = `${this.contentsRef.current.className} first-page`;

      if (this.isContentsHidden() && this.contentsRef.current) {
        this.contentsRef.current.className = `${this.contentsRef.current.className} hide-contents`;
      }
    }

    const [mmiBanner] = document.getElementsByClassName('mmi-banner');
    this.mmiBannerHeight = mmiBanner ? mmiBanner.clientHeight : 0;

    const [documentContainer] = document.getElementsByClassName('document-container');

    this.updateStyle(documentContainer, mmiBanner);
    window.addEventListener('resize', () => this.updateStyle(documentContainer, mmiBanner));

  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isMMIBannerMessageVisible !== this.props.isMMIBannerMessageVisible && this.props.isMMIBannerMessageVisible) {
      const [documentContainer] = document.getElementsByClassName('document-container');
      const [mmiBanner] = document.getElementsByClassName('mmi-banner');

      if (documentContainer && mmiBanner) {
        this.mmiBannerHeight = mmiBanner.clientHeight;
        this.updateStyle(documentContainer, mmiBanner);
      }
    }

    if (this.props.location.pathname !== prevProps.location.pathname && isModalWindowRoute(prevProps.location.pathname)) {
      const selectedProduct = _get(this.props, 'selectedProduct.slug');

      if (selectedProduct && selectedProduct !== this.state.selectedProduct && this.state.documents) {
        const selectedProductIndex = this.state.documents.findIndex((document) => document.slug === selectedProduct);

        if (selectedProductIndex !== -1) {
          const prevSelectedProduct = this.state.selectedProduct;
          const nextSelectedProduct = selectedProduct;

          const slug = safeDocSlugReplace(this.state.slug, prevSelectedProduct, nextSelectedProduct);
          const documentPathname = safeDocSlugReplace(this.props.location.pathname, prevSelectedProduct, nextSelectedProduct);

          this.setState({
            slug,
            content: '',
            navLinksArray: [],
            contentMenu: null,
            isDocumentLoaded: false,
            selectedProduct: _get(this.state.documents, `[${selectedProductIndex}].slug`),
            selectedProductTitle: _get(this.state.documents, `[${selectedProductIndex}].title`),
          }, () => {
            this.parentElement = null;
            this.handleRetrieveDocument(slug, '');

            this.props.history.replace({
              pathname: documentPathname,
              hash: undefined
            });
          });
        }
      }
    }

    if (prevState.isDocumentLoaded !== this.state.isDocumentLoaded && this.state.isDocumentLoaded) {
      const hashValue = this.props.location.hash.replace('#', '');
      const isBreadcrumbUrl = _get(this.props.location, 'state.detail.isBreadcrumbUrl', false);

      if (!isBreadcrumbUrl) {
        this.highlightMatchedParagraph(hashValue);
      }

      if (!_isNull(this.textRef.current)) {
        // Set Product header based on the environment

        // Updates Fachinfo header if it exists
        const fachinfoHeader = this
          .textRef
          .current
          .querySelector('div.product-metadata-block > span');
        
        if (fachinfoHeader) {
          fachinfoHeader.innerHTML = i18next.t('PRODUCT_INFORMATION_HEADER');
        }

        const [submitButton] = this.textRef.current.getElementsByTagName('button');

        if (submitButton) {
          submitButton.addEventListener('click', this.goBack);
        }

        this.normalizeImgSrcPaths();
        this.addZoomImagesHandlers();

        this.addHorizontalScrollTables();
        this.updateScrollTableVisibility();
      }

      setTimeout(() => this.scrollToAnchorFromUrl(hashValue), 500);
    }

    if (prevState.navLinksArray.length !== this.state.navLinksArray.length) {
      this.contentsRef.current && this.setInitialPositionContents();
    }
  }

  handleRetrieveDocument = (slug, hash) => (
    retrieveDocument(slug)
      .then((res) => {
        const titleToReplace = res.data.html.match(/<h1>(.*?)<\/h1>/)[1];
        // Our handlebars/showdown html/md generators love to escape everything in XIP
        const title = titleToReplace && unescapeHTML(titleToReplace);
        let content = res.data.html
          .replace(titleToReplace, '')
          .replace('<h1></h1>', '');

        const documentIdMatch = res.data.html.match(/data-document-id="(\d+)"/);
        const documentValidFrom = res.data.html.match(/data-published-date="(.*)"/);
        const documentExternalUrl = res.data.html.match(/data-external_url="(.*)"/);
        const productMatch = res.data.html.match(/data-product="([^"]*)"/);

        if (documentIdMatch && productMatch) {
          /* eslint-disable no-unused-vars */
          const [_fullDocumentIdMatch, documentId] = documentIdMatch;
          const [_fullProductNameMatch, productHtml] = productMatch;
          const productName = unescapeHTML(productHtml);

          if (documentId) {
            this.handleSelectDrugDropdown(documentId, slugify(productName));
          }
        }

        if (documentValidFrom && documentExternalUrl) {
          const [_fullValidFrom, validFrom] = documentValidFrom;
          const [_fullExternalUrl, externalUrl] = documentExternalUrl;

          this.props.onMMIBannerDate({
            detail: {
              publishedDate: validFrom,
              externalUrl,
            }
          });
        }

        this.handleAddKnowledgeGraph(slug, title);

        this.setState({ title, content, isDocumentLoaded: true }, () => {
          this.props.onDocumentLoaded();
          this.attachScrollHandler();
          this.onScroll();
        });

        window.addEventListener('keydown', this.handleKeyUpAndDown(slug));
        window.addEventListener('keyup', this.handleKeyUpAndDown(slug));

      })
      .catch(() => {
        this.setState({ isDocumentLoaded: true }, () => {
          this.props.onDocumentLoaded();
          if (navigator.userAgent !== 'ReactSnap' && !this.props.history.location.pathname.includes('/something-went-wrong') && !this.state.slug.includes('/unavailable-content')) {
            setTimeout(() => {
              this.props.history.replace({
                pathname: `${getPathnameWithoutModal(this.props.history.location.pathname)}/unavailable-content`,
                hash
              });
            }, 200);
          }
        });
      })
  )

  // secret key combination check to export document (pwd protected)
  keys = {};
  handleKeyUpAndDown = (slug) => (e) => {
    this.keys[e.code] = e.type === 'keydown';
    if (this.keys.KeyX && this.keys.KeyI && this.keys.KeyR) {
      // xir + [1,2,3]
      const format = this.keys.Digit1 ? 'pdf' : this.keys.Digit2 ? 'json' : this.keys.Digit3 ? 'headlines' : false;
      if (format) {
        this.keys = {}; // reset keys now, bc keyup missed during window.open
        window.open(`${config.XipApiUrl}/${format}/documents/${slug}`, 'export');
      }
    }
  };
  
  handleSelectDrugDropdown = (documentId, productName) => {
    getDrugInfos(productName).then((response) => {
      const documents = _get(response, 'data.documents', []);
			const molecules = (_get(response, 'data.molecules') || []).join(', ');
      const selectedDocument = documents.find((document) => document.id.toString() === documentId);

      this.setState({
        documents,
        selectedProduct: _get(selectedDocument, 'slug'),
        selectedProductTitle: _get(selectedDocument, 'title'),
        productCompoundComponent: molecules,
        isSelectLoaded: true,
        drugs: documents.map(({ id, name, title, slug }) => ({ id, name, title, slug }))
      });
    });
  }

  getDocumentPath = () => {
    const documentPath = _get(this.props.match, 'params[0]');
    const isRoutePath = documentPath && documentPath.includes('/');

    if (documentPath && isRoutePath) {
      return getPathnameWithoutModal(documentPath);
    } else if (this.props.match.url.includes('fachinfo')) {
      const documentRoot = config.StoryblokRootFolder;
      const productSlug = slugify(this.props.match.params.productName);
      if (config.Stage === 'aron-prod') {
        return `de-de/${documentRoot}/documents/product-information/mmi-html/` +
          this.props.match.params.documentPath.replace('fachinfo-', `${productSlug}_`);
      }
      return `de-de/${documentRoot}/documents/product-information/mmi-html/${productSlug}/` +
        this.props.match.params.documentPath.replace('fachinfo-', '');
    }
  };

  handleAddKnowledgeGraph = (slug, title) => {
    const description = _get(this.props.location, 'state.detail.description', '');

    const knowledgeGraph = {
      '@context': 'http://schema.org',
      '@type': 'MedicalWebPage',
      'about': {
        '@type': 'MedicalEntity',
        'name': title,
        'url': `${config.XirclesUrl}/documents/${slug}`,
        'description': description
      }
    };

    const knowledgeGraphScript = document.createElement('script');
    knowledgeGraphScript.type = 'application/ld+json';
    knowledgeGraphScript.innerHTML = JSON.stringify(knowledgeGraph);

    const [head] = document.getElementsByTagName('head');
    return head && head.appendChild(knowledgeGraphScript);
  };

  handleRemoveKnowledgeGraph = () => {
    const knowledgeGraph = document.querySelector('script[type="application/ld+json"]');
    return knowledgeGraph && knowledgeGraph.parentNode.removeChild(knowledgeGraph);
  };

  getParagraphElement = (element) => {
    if (element.nextSibling && element.nextSibling.tagName !== 'P') {
      return this.getParagraphElement(element.nextSibling);
    }

    const textNode = Array
      .from(element.nextSibling.childNodes)
      .find((child) => child.nodeType === Node.TEXT_NODE);

    return textNode ? element.nextSibling : this.getParagraphElement(element.nextElementSibling);
  };

  getListItemElement = (element, listItemContent) => {
    if (element.nextSibling && element.nextSibling.tagName !== 'LI') {
      return this.getListItemElement(element.nextSibling, listItemContent);
    }

    const textNode = Array
      .from(element.nextSibling.childNodes)
      .find((child) => child.nodeType === Node.TEXT_NODE);

    return textNode && textNode.wholeText && textNode.wholeText.includes(listItemContent)
      ? element.nextSibling
      : this.getListItemElement(element.nextElementSibling, listItemContent);
  };

  wrapHighlightedParagraph = (closestItem) => {
    const highlightedParagraph = document.createElement('em');
    highlightedParagraph.classList.add('highlighted-paragraph');

    Array
      .from(closestItem.childNodes)
      .forEach((child) => highlightedParagraph.appendChild(child));

    while (closestItem.firstChild) {
      closestItem.removeChild(closestItem.lastChild);
    }

    closestItem.appendChild(highlightedParagraph);
  }

  highlightMatchedParagraph = (hash) => {
    const hashElement = document.getElementById(`${hash}`);
    const listItemContent = _get(this.props.location, 'state.detail.listItemContent');

    if (hashElement) {
      if (listItemContent) {
        const closestListItem = this.getListItemElement(hashElement, listItemContent);

        if (closestListItem) {
          this.wrapHighlightedParagraph(closestListItem);
        }
      } else {
        const closestParagraph = this.getParagraphElement(hashElement);

        if (closestParagraph) {
          this.wrapHighlightedParagraph(closestParagraph);
        }
      }
    }
  };

  scrollToAnchorFromUrl = (hash) => {
    this.correctTopOffsetAttempts = this.correctTopOffsetAttempts - 1;
    const hashElement = document.getElementById(`${hash}`);
    const topOffset = hashElement && hashElement.getBoundingClientRect().top;

    const topOffsetCorrected = this.mmiBannerHeight > 0
      ? topOffset - this.mmiBannerHeight
      : topOffset;

    if (this.topOffset !== topOffsetCorrected) {
      this.topOffset = topOffsetCorrected;
      const coords = hashElement && (window.pageYOffset + topOffsetCorrected);
      // not scoll if coords are less than 250px to not see partially scrolled document small header
      return this.setState({ anchorFromUrlCoordinates: coords > 250 ? coords : 0 });
    }

    return this.correctTopOffsetAttempts !== 0 && setTimeout(() => this.scrollToAnchorFromUrl(hash), 100);
  };

  attachScrollHandler(): void {
    window.addEventListener('scroll', this.onScroll, { passive: true });
    window.addEventListener('resize', this.onScroll);
  }

  componentWillUnmount(): void {
    this.handleRemoveKnowledgeGraph();

    const [grecaptchaBadge] = document.getElementsByClassName('grecaptcha-badge');
    if (grecaptchaBadge) {
      grecaptchaBadge.classList.remove('hide-grecaptcha');
    }

    window.removeEventListener('scroll', this.onScroll);
    window.removeEventListener('resize', this.onScroll);
    window.removeEventListener('resize', this.updateStyle);
    window.removeEventListener('onkeydown', this.handleKeyUpAndDown);
    window.removeEventListener('onkeyup', this.handleKeyUpAndDown);
  }

  updateScrollTableVisibility = () => {
    const tables = this.textRef.current.getElementsByClassName('flex-table');

    Array
      .from(tables)
      .forEach((table, index) => {
        const tableWidth = table.innerWidth || table.clientWidth;

        const [headerRow] = table.getElementsByClassName('flex-row h').length
          ? table.getElementsByClassName('flex-row h')
          : table.getElementsByTagName('tr');

        const headerRowWidth = headerRow.innerWidth || headerRow.clientWidth;

        if (tableWidth > headerRowWidth) {
          this.toggleScrollControl('add', 'right', index, 'scroll-table--hide');
        } else {
          this.toggleScrollControl('remove', 'right', index, 'scroll-table--hide');
        }
      });
  };

  normalizeImgSrcPaths = () => {
    const images = this.textRef.current.getElementsByTagName('img');

    Array
      .from(images)
      .forEach((image) => {
        if (image.getAttribute('src') && !image.getAttribute('src').includes('base64')) {
          image.setAttribute('src', image.getAttribute('src').replace(/\\"/g, ''));
        }
      });
  };

  toggleScrollControl = (action, direction, tableIndex, className) => {
    const scrollControl = document.getElementById(`scroll-table-${direction}-${tableIndex}`);
    return scrollControl && scrollControl.classList[action](className);
  };

  sideScroll = (element, maxScrollLeft, tableIndex, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      this.toggleScrollControl('remove', 'right', tableIndex, 'scroll-table--hide');

      if (element.scrollLeft >= maxScrollLeft) {
        element.scrollLeft = maxScrollLeft;
        this.toggleScrollControl('add', 'right', tableIndex, 'scroll-table--hide');
      }

      scrollAmount += step;

      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
    }, speed);
  };

  handleScrollRightClick = (e) => {
    const splittedId = e.target.id.split('-');
    const tableIndex = splittedId.pop();

    const scrollTable = document.getElementById(`flex-table-${tableIndex}`);

    const [headerRow] = scrollTable.getElementsByClassName('flex-row h').length
      ? scrollTable.getElementsByClassName('flex-row h')
      : scrollTable.getElementsByTagName('tr');

    const headerRowWidth = headerRow.innerWidth || headerRow.clientWidth;
    const maxScrollLeft = headerRowWidth - scrollTable.parentElement.clientWidth;

    this.sideScroll(scrollTable.parentElement, maxScrollLeft, tableIndex, 25, 100, 10);
  };

  handleTableScroll = (e) => {
    const splittedId = e.target.id.split('-');
    const tableIndex = splittedId.pop();

    const scrollTable = document.getElementById(`flex-table-${tableIndex}`);
    const maxScrollLeft = scrollTable.scrollWidth - scrollTable.clientWidth;

    if (scrollTable.scrollLeft >= maxScrollLeft) {
      this.toggleScrollControl('add', 'right', tableIndex, 'scroll-table--hide');
    } else {
      this.toggleScrollControl('remove', 'right', tableIndex, 'scroll-table--hide');
    }
  };

  addHorizontalScrollTables = () => {
    const tables = this.textRef.current.querySelectorAll('.flex-table, .smpc-mmi table');

    Array
      .from(tables)
      .forEach((table, index) => {
        table.id = `flex-table-${index}`;
        table.classList.add('flex-table');

        const wrapper = document.createElement('div');
        wrapper.classList.add('flex-table-wrapper');

        const tableContent = document.createElement('div');
        tableContent.classList.add('flex-table-content');

        table.parentNode.insertBefore(wrapper, table);
        tableContent.appendChild(table);
        wrapper.appendChild(tableContent);

          if (table.clientWidth > wrapper.clientWidth) {
          const scrollRightControl = document.createElement('span');
          scrollRightControl.classList.add('scroll-table-right');
          scrollRightControl.setAttribute('id', `scroll-table-right-${index}`);
          scrollRightControl.addEventListener('click', this.handleScrollRightClick);

          const arrowIcon = document.createElement('i');
          arrowIcon.classList.add('material-icons');
          arrowIcon.innerHTML = 'keyboard_arrow_right';
          arrowIcon.setAttribute('id', `scroll-arrow-icon-right-${index}`);
          scrollRightControl.appendChild(arrowIcon);

          table.addEventListener('scroll', this.handleTableScroll, { passive: true });
          table.appendChild(scrollRightControl);
        }
      });
  };

  getImageIndex = () => {
    const [zoomedImage] = document.getElementsByClassName('zoomed-image');
    return (zoomedImage && zoomedImage.getAttribute('data-index')) || undefined;
  };

  handleExpandButtonClick = (index) => {
    const updatedIndex = index === undefined ? this.getImageIndex() : index;

    if (updatedIndex !== undefined) {
      const [imageWrapper] = document.getElementsByClassName('image-wrapper-' + updatedIndex);

      const [imageContent] = imageWrapper.getElementsByClassName('image-wrapper__content');
      imageContent.classList.toggle('expand-image-' + updatedIndex);
      imageContent.classList.toggle('zoomed-image');

      const [scrim] = document.getElementsByClassName('expand-image__scrim');
      scrim && scrim.classList.toggle('expand-image__scrim--show');

      setTimeout(() => {
        if (scrim.classList.contains('expand-image__scrim--show')) {
          disableBodyScroll(document.getElementsByClassName('figure-container')[0], { reserveScrollBarGap: true });
        } else {
          clearAllBodyScrollLocks();
        }
      }, 0);
    }
  };

  addZoomImagesHandlers = () => {
    const figures = this.textRef.current.getElementsByClassName('figure-container');

    Array
      .from(figures)
      .forEach(async (figure, index) => {
        const [image11] = figure.getElementsByClassName('ratio-1-1');
        const [image21] = figure.getElementsByClassName('ratio-2-1');
        const [image32] = figure.getElementsByClassName('ratio-3-2');

        const image = image11 || image21 || image32;

        if (image) {
          const naturalSize = await getImageSize(image, 'height', 'width');
          figure.style.minWidth = naturalSize.width + 'px';
          figure.style.minHeight = figure.clientHeight + 'px';
          //
          const imgContainer = figure.querySelector('div');
          if (imgContainer) {
            imgContainer.classList.add('image-wrapper', 'image-wrapper-' + index);
            imgContainer.addEventListener('click', () => this.handleExpandButtonClick(index));

            const expandButton = document.createElement('button');
            expandButton.id = 'expand-image-button-' + index;
            expandButton.classList.add('expand-image-button');

            const iconExpand = generateSVG({ fill: 'none', height: '18', width: '18', viewBox: '0 0 18 18', d: 'M7 18v-2H3.41l4.5-4.5-1.41-1.41-4.5 4.5V11H0v7h7m4.5-10.09l4.5-4.5V7h2V0h-7v2h3.59l-4.5 4.5 1.41 1.41z' });
            const iconCollapse = generateSVG({ fill: 'none', height: '27', width: '27', viewBox: '0 0 27 27', d: 'M24.75.135L18 6.885V1.5h-3V12h10.5V9h-5.385l6.75-6.75L24.75.135M1.5 15v3h5.385l-6.75 6.75 2.115 2.115L9 20.115V25.5h3V15H1.5z' });

            const wrapperContent = document.createElement('div');
            wrapperContent.classList.add('image-wrapper__content');

            expandButton.appendChild(iconExpand);
            expandButton.appendChild(iconCollapse);

            wrapperContent.appendChild(expandButton);
            imgContainer.appendChild(wrapperContent);

            const [img] = imgContainer.getElementsByTagName('img');
            img.setAttribute('data-index', index);
            const naturalImageSize = await getImageSize(img);

            imgContainer.style.minHeight = imgContainer.clientHeight - 14 + 'px';
            imgContainer.style.minWidth = imgContainer.clientWidth + 'px';

            wrapperContent.appendChild(img);

            let maxWidth, maxHeight, sizeProportion;

            if (window.innerWidth < window.innerHeight) {
              maxWidth = window.innerWidth > naturalImageSize.width + 20 ? naturalImageSize.width : window.innerWidth - 20;
              sizeProportion = maxWidth / naturalImageSize.width;
              maxHeight = naturalImageSize.height * sizeProportion;
            } else {
              maxHeight = window.innerHeight > naturalImageSize.height + 150 ? naturalImageSize.height : window.innerHeight - 150;
              sizeProportion = maxHeight / naturalImageSize.height;
              maxWidth = naturalImageSize.width * sizeProportion;

              if (maxWidth > window.innerWidth) {
                maxWidth = window.innerWidth > naturalImageSize.width + 150 ? naturalImageSize.width : window.innerWidth - 150;
                sizeProportion = maxWidth / naturalImageSize.width;
                maxHeight = naturalImageSize.height * sizeProportion;
              }
            }

            var expandImageStyle = document.createElement('style');
            expandImageStyle.type = 'text/css';
            expandImageStyle.innerHTML = `
              .expand-image-${index} {
                position: fixed !important;
                top: 50%;
                left: 50%;
                max-width: ${maxWidth}px !important;
                max-height: ${maxHeight}px !important;
                margin-left: -${maxWidth / 2}px !important;
                margin-top: -${maxHeight / 2}px !important;
              }
            `;

            document.head.appendChild(expandImageStyle);
          }
        }
      });

    if (figures.length) {
      const scrim = document.createElement('div');
      scrim.classList.add('expand-image__scrim');
      scrim.addEventListener('click', () => this.handleExpandButtonClick());
      //
      const [documentContainer] = document.getElementsByClassName('document-container');
      documentContainer && documentContainer.appendChild(scrim);
    }
  };

  setInitialPositionContents = () => {
    const topOffset = (this.smallHeaderRef.current.clientHeight + ((this.headerRef.current.clientHeight - this.smallHeaderRef.current.clientHeight) * (this.headerRef.current.clientHeight - window.pageYOffset + 100) / (this.headerRef.current.clientHeight + 100)) + 20);
    this.contentsRef.current.style.top = topOffset + this.mmiBannerHeight + 'px';
  };

  updateStyle = (documentContainer, mmiBanner) => {
    if (documentContainer) {
      documentContainer.style.marginTop = `${mmiBanner ? mmiBanner.clientHeight : 0}px`;
    }

    if (this.textRef && this.textRef.current) {
      this.updateScrollTableVisibility();
    }
  };

  checkClassName = (classList, className) => {
    let isHere = false;
    forEach(classList, (item) => {
      isHere = item === className ? true : isHere;
    });
    return isHere;
  };

  isContentsHidden = () => {
    const { pathname } = this.props.history.location;
    return pathname.includes('videos');
  };

  goBack = () => {
    const { history } = this.props;

    const productName = _get(this.props.location, 'state.detail.productName');

    if (productName) {
      return history.replace(`/${productName}`);
    } else if (this.prevLocation === '/' && this.props.match.params.productName) {
      return history.replace(`/medikamente/${this.props.match.params.productName}`);
    } else {
      return history.goBack();
    }
  };

  handleHeaderWrapperAnimation = () => {
    if (this.headerWrapperRef.current && window.pageYOffset > 5 && window.pageYOffset < this.headerRef.current.clientHeight) {
      this.headerWrapperRef.current.style.top = '-' + window.pageYOffset + 'px';
    }

    if (this.headerWrapperRef.current && window.pageYOffset < 5) {
      this.headerWrapperRef.current.style.top = 0;
    }

    if (this.headerWrapperRef.current && window.pageYOffset >= 125) {
      this.headerWrapperRef.current.style.top = '-125px';
    }
  };

  handleSelectDrug = (selectedProduct) => this.props.history.push({
    pathname: this.props.history.location.pathname + '/select-drug' + this.props.history.location.hash,
    state: { detail: { selectedProduct, drugs: this.state.drugs } },
  });

  handleExpandArea = () => this.setState({ isSelectExpanded: true });

  onScroll = () => {
    if (isDocumentsRoute(this.props.history.location.pathname)) {
      if (this.state.isSelectExpanded) {
        this.setState({ isSelectExpanded: false });
      }

      if (this.textRef.current.clientHeight >= document.documentElement.clientHeight + this.headerRef.current.clientHeight) {
        this.handleHeaderWrapperAnimation();

        if (window.pageYOffset > this.headerRef.current.clientHeight + 100) {
          this.smallHeaderRef.current.style.top = this.mmiBannerHeight ? this.mmiBannerHeight + 'px' : '0';
          this.smallHeaderRef.current.style.opacity = 1;
          if (this.contentsRef.current) {
            if (this.mmiBannerHeight) {
              this.contentsRef.current.style.top = this.mmiBannerHeight + this.smallHeaderRef.current.clientHeight + 20 + 'px';
            } else {
              this.contentsRef.current.style.top = this.smallHeaderRef.current.clientHeight + 20 + 'px';
            }
          }
        } else if (window.pageYOffset < this.headerRef.current.clientHeight / 2 - this.mmiBannerHeight) {
          this.smallHeaderRef.current.style.opacity = 0;
          this.smallHeaderRef.current.style.top = this.mmiBannerHeight ? '0px' : '-65px';
          if (this.contentsRef.current) {
            const topOffset = (this.smallHeaderRef.current.clientHeight + ((this.headerRef.current.clientHeight - this.smallHeaderRef.current.clientHeight + this.mmiBannerHeight) * (this.headerRef.current.clientHeight - window.pageYOffset + 100) / (this.headerRef.current.clientHeight + 100)) + 20);
            this.contentsRef.current.style.top = topOffset + 'px';
          }
        } else if ((window.pageYOffset > this.headerRef.current.clientHeight / 2 - this.mmiBannerHeight) && (window.pageYOffset < this.headerRef.current.clientHeight + 100)) {
          if (this.contentsRef.current) {
            this.smallHeaderRef.current.style.opacity = 1;

            if (this.mmiBannerHeight) {
              const contentsPos = (this.smallHeaderRef.current.clientHeight + ((this.headerRef.current.clientHeight - this.smallHeaderRef.current.clientHeight + this.mmiBannerHeight) * (this.headerRef.current.clientHeight - window.pageYOffset + 100) / (this.headerRef.current.clientHeight + 100)) + 20);
              this.contentsRef.current.style.top = contentsPos > this.mmiBannerHeight + this.smallHeaderRef.current.clientHeight + 20 ? contentsPos + 'px' : this.mmiBannerHeight + this.smallHeaderRef.current.clientHeight + 20 + 'px';
            } else {
              this.contentsRef.current.style.top = (this.smallHeaderRef.current.clientHeight + ((this.headerRef.current.clientHeight - this.smallHeaderRef.current.clientHeight + this.mmiBannerHeight) * (this.headerRef.current.clientHeight - window.pageYOffset + 100) / (this.headerRef.current.clientHeight + 100)) + 20) + 'px';
            }
            
            if (this.mmiBannerHeight) {
              this.smallHeaderRef.current.style.top = (window.pageYOffset < this.mmiBannerHeight ? window.pageYOffset : this.mmiBannerHeight) + 'px';
            } else {
              this.smallHeaderRef.current.style.top = '-' + (this.smallHeaderRef.current.clientHeight * (1 - (window.pageYOffset - this.headerRef.current.clientHeight / 2) / 100)) + 'px';
            }
          }
        }
      } else {
        this.smallHeaderRef.current.style.top = '-' + this.smallHeaderRef.current.clientHeight + 5 + 'px';
        if (this.contentsRef.current) {
          this.contentsRef.current.style.top = (this.smallHeaderRef.current.clientHeight + ((this.headerRef.current.clientHeight - this.smallHeaderRef.current.clientHeight) * (this.headerRef.current.clientHeight - window.pageYOffset + 100) / (this.headerRef.current.clientHeight + 100)) + 20) + 'px';
        }
      }

      const navLinksArray = []

      const regEx = /[0-9]{1,}.[0-9]{1,}./

      this.isWrapperElement = this.textRef.current.childNodes.length === 1
        && this.textRef.current.childNodes[0].localName === 'div';

      if (this.isWrapperElement && !this.parentElement) {
        this.parentElement = this.textRef.current.childNodes[0];
      } else if (!this.parentElement) {
        return setTimeout(() => this.onScroll(), 1000);
      }

      if (!this.state.contentMenu) {
        for (let i = 0; i < this.parentElement.childNodes.length; i++) {
          (this.parentElement.childNodes[i].localName === 'h2' ||
            this.parentElement.childNodes[i].localName === 'h3') &&
            navLinksArray.push(this.parentElement.childNodes[i])
        }

        const temp = navLinksArray.map((item) => {
          if (regEx.test(item.innerText.replace(/[^\d.-]/g, ''))) {
            return item.innerText + 'h3'
          } else {
            return item.innerText
          }
        }
        )

        const formatted = [];
        let count = 0;
        temp.forEach(elem => {
          const isSubsection = /^\d\.\d/g.test(elem);
          if (isSubsection) {
            if (Array.isArray(formatted[count])) {
              formatted[count].push(elem);
            } else {
              count++;
              formatted[count] = [elem];
            }
          } else {
            count++;
            formatted.push(elem);
          }
        })

        this.setState({
          contentMenu: formatted
        })
      }

      !this.state.navLinksArray.length && this.setState({ navLinksArray: navLinksArray })
    }
  };

  render() {
    const { t, isLoggedIn, displayName, onDisplayName, onLogin, onToggleMenu, onLogoClick } = this.props;
    const { isDocumentLoaded, anchorFromUrlCoordinates } = this.state;
    return (
      <div ref={this.containerRef} className={'document-container'}>
        <HelmetWrapper documentTitle={this.state.title} location={this.props.location.pathname} />
        <header
          ref={this.smallHeaderRef}
          className={'small-header'}
        >
          <div>
            <span className='back-button-dummy'/>
            <h1>{t('COMMON_TECHNICAL_INFORMATION')}</h1>
          </div>
          <button
            id="back-button-small"
            className="back-button"
            data-tr-event
            onClick={this.goBack}
          >
            <i className="material-icons">arrow_back</i>
          </button>
          {_get(this.props.themeSettings, 'isSelectAreaVisible', false) && <SelectArea
            isSelectLoaded={this.state.isSelectLoaded}
            selectedProduct={this.state.selectedProductTitle}
            productCompoundComponent={this.state.productCompoundComponent}
            isSelectExpanded={this.state.isSelectExpanded}
            onSelectDrug={() => this.handleSelectDrug(this.state.selectedProduct)}
            onExpandArea={this.handleExpandArea}
          />}
        </header>
        {
          this.state.navLinksArray.length > 0 && !this.props.isIOS12 && (
            <Contents
              containerRef={this.contentsRef}
              contentMenu={this.state.contentMenu}
              textRef={this.parentElement}
              headerRef={this.headerRef}
              anchorFromUrlCoordinates={anchorFromUrlCoordinates}
              navLinksArray={this.state.navLinksArray}
              topHeader={`${t('COMMON_TOP')}`}
              slug={this.state.slug}
              updateParentUrl={this.props.updateParentUrl}
              isMMIBannerVisible={_get(this.props.themeSettings, 'isMMIBannerVisible', false)}
            />)
        }
        <div className="header-wrapper" ref={this.headerWrapperRef}>
          <header ref={this.headerRef}>
            <img
              alt="Xircle"
              data-tr-event
              id="xircle-logo"
              className="xircles-logo"
              onClick={onLogoClick}
            />
            <div className="login">
              {config.RegistrationEnabled && (isLoggedIn ?
                <h6 onClick={onDisplayName}>{displayName}</h6> :
                <h6 data-tr-event onClick={onLogin}>{t('COMMON_MENU_LOGIN')}</h6>)
              }
              {_get(this.props.themeSettings, 'isMenuVisible', false) && <button id="menu-button" data-tr-event onClick={onToggleMenu}><i data-tr-event className="material-icons menu-icon">menu</i></button>}
            </div>
            <div className="title-wrapper">
              <span className='back-button-dummy'/>
              <h1>{t('COMMON_TECHNICAL_INFORMATION')}</h1>
            </div>
            <button
              id="back-button"
              className="back-button"
              data-tr-event
              ref={this.arrowBackRef}
              onClick={this.goBack}
            >
              <i className="material-icons">arrow_back</i>
            </button>
            {_get(this.props.themeSettings, 'isSelectAreaVisible', false) && (
              <SelectArea
                isSelectLoaded={this.state.isSelectLoaded}
                selectedProduct={this.state.selectedProductTitle}
                productCompoundComponent={this.state.productCompoundComponent}
                onSelectDrug={() => this.handleSelectDrug(this.state.selectedProduct)}
              />)
            }
          </header>
        </div>
        {/* TODO: Check remove additional Loading Spinner and check Contents postions depends on */}
        <LoadingSpinner className='loading-spinner__document-page loading-spinner__document-page--loaded' />
        <LoadingSpinner className={`${_get(this.props.themeSettings, 'isMMIBannerVisible', false) ? 'loading-spinner__document-page--fixed' : 'loading-spinner__document-page'} ${isDocumentLoaded ? 'loading-spinner__document-page--loaded' : ''}`} />
        {
          isIE() ?
            <div className={'main'}>
              <div ref={this.textRef} className={'content'}>{Parser(this.state.content)}</div>
            </div> :
            <main>
              <div ref={this.textRef} className={'content'}>{Parser(this.state.content)}</div>
            </main>
        }
        {_get(this.props.themeSettings, 'isMenuVisible', false) && <MenuContainer />}
      </div>
    )
  }
}

export default withTranslation()(Document);